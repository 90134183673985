import {
  Center,
  Title,
  Text,
  Flex,
  Box,
  BackgroundImage,
  Stack,
  Image,
  Button,
  Paper,
  SimpleGrid,
  UnstyledButton,
  List,
  ThemeIcon,
} from "@mantine/core";

import { Carousel } from "@mantine/carousel";

import { useMediaQuery } from "@mantine/hooks";

import { Checks } from "tabler-icons-react";

import Contact from "./Contact";

const careerJournalImages = [
  "/careerjournal/1-min.png",
  "/careerjournal/2-min.png",
  "/careerjournal/3-min.png",
  "/careerjournal/4-min.png",
  "/careerjournal/5-min.png",
  "/careerjournal/6-min.png",
  "/careerjournal/7-min.png",
  "/careerjournal/8-min.png",
  "/careerjournal/11-min.png",
  "/careerjournal/12-min.png",
  "/careerjournal/13-min.png",
];

function CareerJournal() {
  const matches = useMediaQuery("(min-width: 56.25em)");
  return (
    <Box
      id="careerjournal"
      my={20}
      mx="auto"
      p={50}
      w={matches ? "900px" : "100%"}
      bg="#FBF4EA"
      sx={(theme) => ({
        borderRadius: "5px",
      })}
    >
      <Flex
        direction={{ base: "column", sm: "row" }}
        gap={{ base: "sm", sm: "lg" }}
        justify={{ base: "space-between", sm: "center" }}
      >
        <Box w="300px" mx="auto">
          <Center>
            <Image src="/careerjournal.png" />
          </Center>
        </Box>
        <Box
          sx={(theme) => ({
            maxWidth: "400px",
          })}
          mx="auto"
        >
          <Stack align="center" gap="xl">
            <Title weight="600" order={2}>
              Career Journal
            </Title>
            <Text size="md" align="center">
              De Career Journal - is je ultieme gids, je doelenplanner, je
              werkboek voor loopbaanontwikkeling en je visionaire partner,
              allemaal in één. Met deze praktische journal breng je jouw
              grootste ambities tot leven door ze om te zetten in concrete
              stappen die je elke dag/week kunt plannen. Zo werk je actief aan
              het realiseren van je persoonlijke en professionele doelen! Van
              het proactiever leren netwerken, tot het beter kunnen promoten van
              jouw skills tot het realiseren van een gezonde work-life balance -
              deze journal ondersteunt je op elke stap van je reis naar succes.
            </Text>
            <Button
              bg="#fda715"
              mt="20px"
              w="150px"
              component="a"
              target="_blank"
              href="https://shop.tsnatee.com/checkout/career-journal"
            >
              Bestel Vandaag
            </Button>
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
}

function BulletPoints() {
  const matches = useMediaQuery("(min-width: 56.25em)");
  return (
    <Box
      id="careerjournal"
      my={20}
      w="100%"
      sx={(theme) => ({
        borderRadius: "5px",
      })}
    >
      <Center>
        <Title weight="600" order={2} mt="1rem">
          Wat zit er in de Career Journal?
        </Title>
      </Center>
      <Stack mx="1rem" mt="2rem" align="center" withPadding gap="lg">
        <List size="">
          <List.Item
            mb="1rem"
            icon={
              <ThemeIcon variant="white" size={24} radius="xl">
                <Checks color="#7b0144" />
              </ThemeIcon>
            }
          >
            <Text weight="800">Een datumloze jaarplanner</Text>
            <Text>
              Begin jouw reis op elk moment, zonder te wachten tot morgen. Jouw
              planning begint vandaag.
            </Text>
          </List.Item>

          <List.Item
            mb="1rem"
            icon={
              <ThemeIcon variant="white" size={24} radius="xl">
                <Checks color="#7b0144" />
              </ThemeIcon>
            }
          >
            <Text weight="800">
              Vijf inspirerende video's en Check-ins voor energie en motivatie
            </Text>
            <Text>
              Laat je inspireren en versterken met onze reeks video's, ontworpen
              om jouw drive en vastberadenheid te vergroten.
            </Text>
          </List.Item>
          <List.Item
            mb="1rem"
            icon={
              <ThemeIcon variant="white" size={24} radius="xl">
                <Checks color="#7b0144" />
              </ThemeIcon>
            }
          >
            <Text weight="800">
              Wekelijkse to-do lijsten en reflecties voor jouw carrière
            </Text>
            <Text>
              Houd jouw taken georganiseerd en reflecteer op jouw professionele
              groei, elke week opnieuw.
            </Text>
          </List.Item>
          <List.Item
            mb="1rem"
            icon={
              <ThemeIcon variant="white" size={24} radius="xl">
                <Checks color="#7b0144" />
              </ThemeIcon>
            }
          >
            <Text weight="800">
              Doelplannen voor gedetailleerde actiestappen
            </Text>
            <Text>
              Breng jouw doelen tot leven door ze te plannen en de stappen te
              noteren die nodig zijn om ze te bereiken.
            </Text>
          </List.Item>
        </List>
        <Box w={matches ? "1000px" : "100%"} mx="2rem">
          <Center>
            <Title weight="600" order={2} mt="1rem" mb="2rem">
              Blader door de Career Journal
            </Title>
          </Center>
          <Carousel
            align="start"
            height={500}
            slideSize="30%"
            slideGap="lg"
            controlSize={30}
            dragFree
            draggable={true}
            withIndicators
            breakpoints={[
              { maxWidth: "md", slideSize: "30%" },
              { maxWidth: "sm", slideSize: "60%" },
            ]}
            styles={{
              indicator: {
                background: "#fada01",
              },
            }}
          >
            {careerJournalImages.map((src, index) => (
              <Carousel.Slide key={index}>
                <Image src={src} />
              </Carousel.Slide>
            ))}
          </Carousel>
        </Box>
      </Stack>
    </Box>
  );
}

function Hero() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box>
      <BackgroundImage src="/tsnatee-careerjournal-2.jpg" radius="xs">
        <Box
          sx={(theme) => ({
            height: "900px",
          })}
        ></Box>
      </BackgroundImage>
    </Box>
  );
}

function CareerJournalPage() {
  return (
    <>
      <Hero />
      <CareerJournal />
      <BulletPoints />

      <Contact />
    </>
  );
}

export default CareerJournalPage;
