import {
  Box,
  Text,
  BackgroundImage,
  Center,
  Image,
  SimpleGrid,
  List,
  ThemeIcon,
  Title,
  Stack,
  AspectRatio,
} from "@mantine/core";

import { useParams } from "react-router-dom";

import Contact from "./Contact";

import { useMediaQuery } from "@mantine/hooks";

import { InfoCircle, Checks } from "tabler-icons-react";

function SpeakerHero() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box sx={{ width: "100%", overflow: "hidden", position: "relative" }}>
      <video
        style={{ width: "100%", height: "auto", display: "block" }} // Ensures video is responsive; display:block removes unnecessary space
        autoPlay
        playsInline
        loop
        muted // Recommended for autoplaying videos in web contexts
      >
        <source src="/speaker_video.mp4" type="video/mp4" />
        Your browser does not support video.
      </video>
      <Box
        sx={{
          position: "absolute",
          top: 0, // Adjust as needed to position the text
          left: 0, // Adjust as needed
          right: 0, // Center the text horizontally
          bottom: 0, // Center the text vertically
          display: "flex",
          alignItems: "center", // Centers text vertically in the box
          justifyContent: "center", // Centers text horizontally in the box
        }}
      >
        <Center>
          <Box
            sx={(theme) => ({
              width: "600px",
              textAlign: "center",
            })}
          >
            <Title
              order={2}
              c="white"
              size={matches ? "2.5rem" : "1.5rem"}
              w="600px"
              fw="600"
              color="black"
            >
              Tsnatee als Spreker
            </Title>

            <Center>
              <Image
                maw={100}
                radius="md"
                src="./logo_optimized_vector.svg"
                alt="Logo"
              />
            </Center>
          </Box>
        </Center>
      </Box>
    </Box>
  );
}
function Speaker() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <>
      <SpeakerHero />
      <Box mx="auto" p={20} w={matches ? "700px" : "100%"}>
        <SimpleGrid cols={2} mx="auto" mb={50} spacing="xl">
          <Stack>
            <Title order={4} weight={800}>
              Te boeken als:
            </Title>
            <List
              size={matches ? "md" : "xs"}
              spacing="xs"
              center
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" />
                </ThemeIcon>
              }
            >
              <List.Item>Keynote spreker, Live Talks & events</List.Item>
              <List.Item>Award shows, panel-lid</List.Item>
              <List.Item>
                TV reclames, virtual learning, social Videos
              </List.Item>
              <List.Item>Workshop Trainer</List.Item>
            </List>
          </Stack>
          <Stack>
            <Title order={4} weight={700}>
              Spreekt over:
            </Title>
            <List
              spacing="xs"
              size={matches ? "md" : "xs"}
              center
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" />
                </ThemeIcon>
              }
            >
              <List.Item>Personal Branding</List.Item>
              <List.Item>Culturele Intelligentie</List.Item>
              <List.Item>Women empowerment</List.Item>
              <List.Item>Motivatie</List.Item>
              <List.Item>Loopbaanontwikkeling</List.Item>
            </List>
          </Stack>
        </SimpleGrid>

        <Text>
          Tsnatee is een energieke, meertalige goal-getting ondernemer met een
          passie voor het inspireren van mensen. Met haar hands-on werkwijze en
          energieke presentatie geeft ze haar publiek niet alleen een flinke
          dosis positieve energie mee naar huis, maar ook concrete handvatten en
          inzichten waar je meteen mee aan de slag kan.{" "}
        </Text>
        <br />
        <Text>
          Tsnatee begon haar carrière in de corporate zakenwereld, waar ze
          werkte als sales account manager en als marketing- en eventmanager in
          de IT. Als enige zwarte vrouw in een witte mannenwereld heeft ze zich
          actief moeten bewijzen en bewust moeten profileren om herkend en
          erkend te worden voor datgene waar ze echt goed in is.{" "}
        </Text>
        <br />
        <Text>
          Tsnatee weet als geen ander hoe het voelt om vast te zitten in een
          sleur, verlangend naar meer kansen, terwijl doorgroeien geen optie
          lijkt. Juist vanwege die persoonlijke ervaring besloot ze het heft in
          eigen hand te nemen en haar passies, zoals presenteren, trainen en
          coachen, te ontplooien en actief te delen met haar werkgever. Hierdoor
          ontpopte ze zich tot dé personal branding guru binnen de organisatie
          waar ze werkte, wat resulteerde in nieuwe kansen binnen en buiten de
          organisatie.
        </Text>
        <br />
        <Text>
          Haar missie, geworteld in de ervaring van haar eigen loopbaan
          transformatie, gaat verder dan persoonlijk succes. Tsnatee is
          vastbesloten anderen energie te geven en hen te inspireren om hun
          passies binnen hun huidige baan te ontdekken en te omarmen. Ze streeft
          ernaar werknemers te empoweren om hun werk met hernieuwde energie en
          bevlogenheid aan te pakken, zodat ze niet alleen hun eigen potentieel
          kunnen realiseren, maar ook een waardevolle bijdrage leveren aan het
          succes van hun huidige werkgever.
        </Text>
        <br />
        <Text>
          Als voorstander van inclusiviteit begon Tsnatee met het geven van
          workshops op het gebied van personal branding, waarin ze anderen
          inspireert om hun eigen unieke personal brand te creëren en succesvol
          te zijn in hun huidige professionele omgeving. Haar toewijding aan
          deze missie kreeg verdere vorm met de lancering van haar boek "The
          Career Journal", waarin ze lezers voorziet van essentiële tools en
          inzichten om te groeien binnen hun organisatie, hun passie te vinden,
          en zichzelf te blijven ontwikkelen, zowel in loondienst als als
          zelfstandige professional.
        </Text>
        <br />
        <Text>
          Uiteindelijk startte vanuit haar passie om mensen te begeleiden en
          inspireren haar eigen consultancy firm, waarmee ze nog steeds
          werknemers en bedrijven coacht op het gebied van personal branding en
          culturele diversiteit, met behulp van workshops, speeches, keynotes en
          masterclasses. Zoals Tsnatee altijd zegt: "Shine bright! Pak je kansen
          en creëer je eigen podium!"
        </Text>
      </Box>
    </>
  );
}

function TrainerHero() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box sx={{ width: "100%", overflow: "hidden", position: "relative" }}>
      <video
        style={{ width: "100%", height: "auto", display: "block" }} // Ensures video is responsive; display:block removes unnecessary space
        autoPlay
        playsInline
        loop
        muted // Recommended for autoplaying videos in web contexts
      >
        <source src="/trainer_video.mp4" type="video/mp4" />
        Your browser does not support video.
      </video>
      <Box
        sx={{
          position: "absolute",
          top: 0, // Adjust as needed to position the text
          left: 0, // Adjust as needed
          right: 0, // Center the text horizontally
          bottom: 0, // Center the text vertically
          display: "flex",
          alignItems: "center", // Centers text vertically in the box
          justifyContent: "center", // Centers text horizontally in the box
        }}
      >
        <Center>
          <Box
            sx={(theme) => ({
              width: "600px",
              textAlign: "center",
            })}
          >
            <Title
              order={2}
              c="white"
              size={matches ? "2.5rem" : "1.5rem"}
              fw="600"
              color="black"
            >
              Tsnatee als trainer
            </Title>

            <Center>
              <Image
                maw={100}
                radius="md"
                src="./logo_optimized_vector.svg"
                alt="Logo"
              />
            </Center>
          </Box>
        </Center>
      </Box>
    </Box>
  );
}

function Trainer() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <>
      <TrainerHero />

      <Box mx="auto" p={20} w={matches ? "700px" : "100%"}>
        <Text>
          Ben je op zoek naar een bruisende teambuilding ervaring die niet
          alleen je team verbindt, maar ook concrete oplossingen biedt voor de
          uitdagingen waar jouw team mee te maken heeft? Stel je voor dat jouw
          medewerkers niet alleen beter leren samenwerken, maar ook ontdekken
          hoe ze hun individuele talenten kunnen benutten om bij te dragen aan
          het grotere geheel. Dat is precies wat Tsnatee's workshops bieden.
        </Text>
        <br />
        <Text>
          In deze dynamische workshops gaat ze verder dan alleen het versterken
          van de team spirit. Tsnatee gelooft en en uit eigen ervaring begrijpt
          ze dat medewerkers zich moeten kunnen uiten, zich gewaardeerd moeten
          voelen en de ruimte moeten krijgen om te groeien binnen de
          organisatie.
        </Text>
        <br />
        <Text>
          Stel je voor dat jouw team niet alleen leert hoe ze hun werkprestaties
          kunnen delen op social media, maar ook ontdekt hoe ze hun persoonlijke
          merk kunnen versterken en zichzelf op de kaart kunnen zetten als
          experts in hun vakgebied. Het delen van successen op social media kan
          niet alleen de betrokkenheid vergroten, maar ook nieuw talent
          aantrekken, omdat mensen mensen aantrekken.
        </Text>
        <br />
        <Text>
          De hands-on workshops zitten vol energie en oefeningen die de
          deelnemers actief betrekken en inspireren. Tsnatee biedt praktische
          tools om het delen van werkprestaties te stimuleren, talent te
          behouden tijdens periodes van hoge werkdruk, creativiteit te
          bevorderen en de betrokkenheid te vergroten. Het resultaat?
          Medewerkers die vol passie en energie naar hun werk gaan, die trots
          zijn op wat ze bereiken en die zich gesteund voelen door hun
          organisatie.
        </Text>
        <br />
        <Text>
          <Text component="span" fw={600}>
            ‌Taal:
          </Text>{" "}
          <Text component="span"> Nederlands, Engels en Papiaments </Text>
        </Text>
        <Text>
          <Text component="span" fw={600}>
            Duur:
          </Text>{" "}
          <Text component="span">1.5 - 3 uur ‌ </Text>
        </Text>
        <br />
        <Text>
          Benieuwd naar hoe Tsnatee’s workshops jouw organisatie kunnen
          versterken? Plan nu direct een gesprek in en ontdek de mogelijkheden:
        </Text>
        <br />
        <Box>
          <iframe
            src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0cuXNRjZYvYxdwalWS-62vHkUFHAwUieKcVyQFlfSv8R_BtMBxYj4qt6MWXJS_qHqgVTu3-kiN?gv=true"
            width="100%"
            height="700"
            frameborder="0"
          ></iframe>
        </Box>
        <br />
        <Text>
          ‌Wist je dit? Tsnatee’s workshops worden ook aangeboden als gastlessen
          op scholen? Lees meer over deze unieke ervaring in haar LinkedIn-post:
        </Text>
        <Text underline color="blue">
          <a
            target="_blank"
            href="https://www.linkedin.com/posts/tsnatee-elisa_brandingworkshop-gastspreker-collegetour-activity-7155586736868265984-HRDq"
          >
            https://www.linkedin.com/posts/tsnatee-elisa_brandingworkshop-gastspreker-collegetour
          </a>
        </Text>
      </Box>
    </>
  );
}

function Presenter() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <>
      <Box>
        <BackgroundImage src="/dagvoorzitter.jpg" radius="xs">
          <Box
            sx={(theme) => ({
              height: "900px",
            })}
          >
            <Center>
              <Box
                sx={(theme) => ({
                  width: "600px",
                  textAlign: "center",
                  paddingTop: "400px",
                })}
              >
                <Title
                  order={2}
                  c="white"
                  size={matches ? "2.5rem" : "1.5rem"}
                  w="600px"
                  fw="600"
                  color="black"
                >
                  Tsnatee als Dagvoorzitter
                </Title>

                <Center>
                  <Image
                    maw={100}
                    radius="md"
                    src="./logo_optimized_vector.svg"
                    alt="Logo"
                  />
                </Center>
              </Box>
            </Center>
          </Box>
        </BackgroundImage>
      </Box>

      <Box mx="auto" p={20} w={matches ? "700px" : "100%"}>
        <Text ff="Montserrat">
          Tsnatee is geboren voor het podium. Vanaf jonge leeftijd stond ze al
          op het podium en voor de camera, als danseres, zangeres en
          musicalster. Haar reis leidde haar later ook naar de rol van
          presentatrice, keynote speaker en dagvoorzitter. Zeg je energie, dan
          zeg je Tsnatee. Met haar sprankelende persoonlijkheid en brede lach
          geeft ze elk publiek een goed gevoel.
        </Text>
        <br />
        <Text>
          Tijdens haar carrière bij een van 's werelds grootste techbedrijven,
          organiseerde, regisseerde en modereerde Tsnatee talloze evenementen,
          workshops en seminars voor partners van over de hele wereld. Tsnatee
          weet zowel achter als voor de schermen, precies wat er komt kijken bij
          het organiseren van een succesvol evenement.
        </Text>
        <br />
        <Text>
          Wat Tsnatee onderscheidt, is haar durf om scherpe vragen te stellen en
          haar vermogen om het publiek zonder stress te managen. Als
          dagvoorzitter en presentatrice, zowel op televisie als online,
          beschikt Tsnatee over de vaardigheid om snel beslissingen te nemen en
          flexibel in te spelen op de situatie. Tsnatee is quick-on-her-feet en
          kan op charmante en humoristische wijze moeiteloos omgaan met
          onverwachte situaties op het podium, waardoor uw evenement altijd een
          succes wordt en uw publiek en sprekers zich thuis voelen in de zaal.
        </Text>
        <br />
        <Text>
          Als trainer en coach heeft Tsnatee ervaring met een breed scala aan
          doelgroepen; van studenten tot C-level directieleden. Ze is scherp en
          vindt snel connecties en common ground met elk type publiek waardoor
          ze in staat is een sfeer te creëren die aansluit bij de behoefte van
          het moment. Dankzij haar ervaringen op het gebied van culturele
          intelligentie en diversiteit en inclusie, kan ze effectief samenwerken
          met publiek, panelleden en sprekers met diverse achtergronden en weet
          ze tevens een inclusieve dynamiek succesvol te bevorderen.
        </Text>
        <br />
        <Text weight={800}>
          Met Tsnatee als dagvoorzitter bent je verzekerd van een energieke,
          goed geleide en inclusieve ervaring die ieders verwachtingen
          overtreffen.
        </Text>
      </Box>

      <Box mx="auto" p={20} w={matches ? "700px" : "100%"}>
        <AspectRatio ratio={16 / 9}>
          <iframe
            src="https://www.youtube.com/embed/lccTasTZLHA"
            title="Aftermovie Diversity Dinner 2024"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </AspectRatio>
        <Center>
          <Text mt="5px">
            Bekijk de aftermovie van Diversity Dinner met Tsnatee als
            dagvoorzitter.
          </Text>
        </Center>
      </Box>
    </>
  );
}

function Service() {
  let { slug } = useParams(); // Assuming the route parameter is named `slug`
  const matches = useMediaQuery("(min-width: 56.25em)");

  function content(slug) {
    switch (slug) {
      case "speaker":
        return <Speaker />;
      case "trainer":
        return <Trainer />;
      case "presenter":
        return <Presenter />;
      default:
        return <Text>Service not found</Text>;
    }
  }

  return (
    <>
      {content(slug)}
      <Contact />
    </>
  );
}

export default Service;
