import { AppShell } from "@mantine/core";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

function App() {
  return (
    <>
      <AppShell
        padding="md"
        header={<Header />}
        // footer={<Footer />}
        styles={(theme) => ({
          main: {
            minHeight: 0,
            padding: 0,
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        })}
      >
        <Outlet />
      </AppShell>
      <Footer />
    </>
  );
}

export default App;
